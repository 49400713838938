import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentAvatar.figmaUrl.ios} codeUrl={checklists.componentAvatar.codeUrl.ios} checklists={checklists.componentAvatar.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Avatar are used to defines graphical representation of a user or the user’s character or persona`}</p>
    <p>{`Use Legion avatar styles to modify avatar with lots of attributes that make you easier`}</p>
    <p><strong parentName="p">{`Available themes: ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy`}</strong></p>
    <div className="divi" />
    <h2>{`Variant`}</h2>
    <p>{`Legion iOS Have 3 Variant of Avatar :`}</p>
    <h3><strong parentName="h3">{` Avatar Image `}</strong></h3>
    <div {...{
      "style": {
        "display": "flex",
        "justifyContent": "center"
      }
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F9e768d2e-d1af-4d71-9cff-baa232ae02ce%2FUntitled.png?id=f639a123-b13c-47d5-ab63-d4681f2625ac&table=block&spaceId=35a14a17-7ee4-461e-a539-293d39e34c63&width=270&userId=0a64858e-7521-4427-8950-d546112789a2&cache=v2"
      }}></img>
    </div>
    <p>{`A. Avatar Image Local`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarImage(
    imageNamed: "SomeAssetName",
)
.setBadgeOn(isOn: true)
`}</code></pre>
    <p>{`B. Avatar Image System`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarImage(
    systemName: "person.3.fill",
)
.setBadgeOn(isOn: true)
`}</code></pre>
    <p>{`C. Avatar Image Async`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarImage(
    urlString: "https://i.ibb.co/1btpjhH/persegipanjangvertical.jpg",
)
.setBadgeOn(isOn: true)
.setPlaceholder(
    isProgressViewOn: true
)
`}</code></pre>
    <h3><strong parentName="h3">{` Avatar Icon `}</strong></h3>
    <div {...{
      "style": {
        "display": "flex",
        "justifyContent": "center"
      }
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2Ffc8d2b8a-ded5-4913-8a2d-4258a6bd28d3%2FUntitled.png?id=13756358-3777-4888-9373-531e9b7944fe&table=block&spaceId=35a14a17-7ee4-461e-a539-293d39e34c63&width=260&userId=0a64858e-7521-4427-8950-d546112789a2&cache=v2"
      }}></img>
    </div>
    <p>{`A. Avatar Icon Local`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarIcon(
    imageNamed: "SomeAssetName",
)
.setBadgeOn(isOn: true)
`}</code></pre>
    <p>{`B. Avatar Icon System`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarIcon(
    systemName: "house"
)
.setBadgeOn(isOn: true)
`}</code></pre>
    <p>{`C. Avatar Icon Async`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarIcon(
    urlString: "https://i.ibb.co/yQPCHqz/houseicon.png",
)
.setBadgeOn(isOn: true)
.setPlaceholder(
    isProgressViewOn: true
)
`}</code></pre>
    <h3><strong parentName="h3">{` Avatar Initial `}</strong></h3>
    <div {...{
      "style": {
        "display": "flex",
        "justifyContent": "center"
      }
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F29dc6c4a-8f1b-4090-8810-5e935053edba%2FScreen_Shot_2022-11-17_at_10.49.29_AM.png?table=block&id=543d12cb-9868-4850-8013-7547341713e8&spaceId=35a14a17-7ee4-461e-a539-293d39e34c63&width=280&userId=00fada7e-1357-4bc5-acbd-74e1e4633cb0&cache=v2"
      }}></img>
    </div>
    <p>{`Avatar Initial should add `}<inlineCode parentName="p">{`label: "value"`}</inlineCode>{` where `}<inlineCode parentName="p">{`value`}</inlineCode>{` consist the avatar initial`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarInitials(
    label: "Telkom Indonesia",
)
.setBadgeOn(isOn: true)
`}</code></pre>
    <div className="divi" />
    <h2>{`Attribute`}</h2>
    <p>{`Legion Have 3 Attributes for Costumize Avatar :`}</p>
    <h3><strong parentName="h3">{` Size `}</strong></h3>
    <p>{`This size attribute for user to choose the size of the avatar.`}</p>
    <p>{`Choose type avatar `}<inlineCode parentName="p">{`LGNAvatarImage`}</inlineCode>{` , `}<inlineCode parentName="p">{`LGNAvatarIcon`}</inlineCode>{` or `}<inlineCode parentName="p">{`LGNAvatarInitial`}</inlineCode>{` , add sources `}<inlineCode parentName="p">{`imageNamed: "Link"`}</inlineCode>{` , `}<inlineCode parentName="p">{`systemName: "Link"`}</inlineCode>{` or `}<inlineCode parentName="p">{`urlString: "Link"`}</inlineCode>{` where `}<inlineCode parentName="p">{`Link`}</inlineCode>{` consist the value of the image. And add `}<inlineCode parentName="p">{`size: .sizeAvatar`}</inlineCode>{` where `}<inlineCode parentName="p">{`.sizeAvatar`}</inlineCode>{` consist `}<inlineCode parentName="p">{`.xxsmall`}</inlineCode>{`, `}<inlineCode parentName="p">{`.xsmall`}</inlineCode>{`, `}<inlineCode parentName="p">{`.small`}</inlineCode>{`, `}<inlineCode parentName="p">{`.medium`}</inlineCode>{` or `}<inlineCode parentName="p">{`.large`}</inlineCode></p>
    <p>{`This code sample demonstrates how to modify the size of the avatar :`}</p>
    <h4>{`LGNAvatarImage`}</h4>
    <p><strong parentName="p">{` Local Image `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/avatar/ios-avatar-image-local.png"
      }}></img>
    </div>
    <p>{`A. Avatar Image Local xxsmall`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarImage(
    imageNamed: "SomeAssetName",
    size: .xxsmall
)
.setBadgeOn(isOn: true)
`}</code></pre>
    <p>{`B. Avatar Image Local xsmall`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarImage(
    imageNamed: "SomeAssetName",
    size: .xsmall
)
.setBadgeOn(isOn: true)
`}</code></pre>
    <p>{`C. Avatar Image Local small`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarImage(
    imageNamed: "SomeAssetName",
    size: .small
)
.setBadgeOn(isOn: true)
`}</code></pre>
    <p>{`D. Avatar Image Local medium`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarImage(
    imageNamed: "SomeAssetName",
    size: .medium
)
.setBadgeOn(isOn: true)
`}</code></pre>
    <p>{`E. Avatar Image Local large`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarImage(
    imageNamed: "SomeAssetName",
    size: .large
)
.setBadgeOn(isOn: true)
`}</code></pre>
    <p><strong parentName="p">{` System Image `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/avatar/ios-avatar-image-system.png"
      }}></img>
    </div>
    <p>{`A. Avatar Image System xxsmall`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarImage(
    systemName: "person",
    size: .xxsmall
)
.setBadgeOn(isOn: true)
`}</code></pre>
    <p>{`B. Avatar Image System xsmall`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarImage(
    systemName: "person",
    size: .xsmall
)
.setBadgeOn(isOn: true)
`}</code></pre>
    <p>{`C. Avatar Image System small`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarImage(
    systemName: "person",
    size: .small
)
.setBadgeOn(isOn: true)
`}</code></pre>
    <p>{`D. Avatar Image System medium`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarImage(
    systemName: "person",
    size: .medium
)
.setBadgeOn(isOn: true)
`}</code></pre>
    <p>{`E. Avatar Image System large`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarImage(
    systemName: "person",
    size: .large
)
.setBadgeOn(isOn: true)
`}</code></pre>
    <p><strong parentName="p">{` Image Async `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/avatar/ios-avatar-image-remote.png"
      }}></img>
    </div>
    <p>{`A. Avatar Image Async xxsmall`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarImage(
    urlString: "https:someurl.co/image.jpg",
    size: .xxsmall
)
.setBadgeOn(isOn: true)
.setPlaceholder(
    isProgressViewOn: true
)
`}</code></pre>
    <p>{`B. Avatar Image Async xsmall`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarImage(
    urlString: "https:someurl.co/image.jpg",
    size: .xsmall
)
.setBadgeOn(isOn: true)
.setPlaceholder(
    isProgressViewOn: true
)
`}</code></pre>
    <p>{`C. Avatar Image Async small`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarImage(
    urlString: "https:someurl.co/image.jpg",
    size: .small
)
.setBadgeOn(isOn: true)
.setPlaceholder(
    isProgressViewOn: true
)
`}</code></pre>
    <p>{`D. Avatar Image Async medium`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarImage(
    urlString: "https:someurl.co/image.jpg",
    size: .medium
)
.setBadgeOn(isOn: true)
.setPlaceholder(
    isProgressViewOn: true
)
`}</code></pre>
    <p>{`E. Avatar Image Async large`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarImage(
    urlString: "https:someurl.co/image.jpg",
    size: .large
)
.setBadgeOn(isOn: true)
.setPlaceholder(
    isProgressViewOn: true
)
`}</code></pre>
    <h4>{`LGNAvatarIcon`}</h4>
    <p><strong parentName="p">{` Local Image `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/avatar/ios-avatar-icon-local.png"
      }}></img>
    </div>
    <p>{`A. Avatar Icon Local xxsmall`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarIcon(
  imageNamed: "SomeAssetName",
  size: .xxsmall
)
.setBadgeOn(isOn: true)
`}</code></pre>
    <p>{`B. Avatar Icon Local xsmall`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarIcon(
  imageNamed: "SomeAssetName",
  size: .xsmall
)
.setBadgeOn(isOn: true)
`}</code></pre>
    <p>{`C. Avatar Icon Local small`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarIcon(
  imageNamed: "SomeAssetName",
  size: .small
)
.setBadgeOn(isOn: true)
`}</code></pre>
    <p>{`D. Avatar Icon Local medium`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarIcon(
  imageNamed: "SomeAssetName",
  size: .medium
)
.setBadgeOn(isOn: true)
`}</code></pre>
    <p>{`E. Avatar Icon Local large`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarIcon(
  imageNamed: "SomeAssetName",
  size: .large
)
.setBadgeOn(isOn: true)
`}</code></pre>
    <p><strong parentName="p">{` System Image `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/avatar/ios-avatar-icon-system.png"
      }}></img>
    </div>
    <p>{`A. Avatar Icon System xxsmall`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarIcon(
  systemName: "house",
  size: .xxsmall
)
.setBadgeOn(isOn: true)
`}</code></pre>
    <p>{`B. Avatar Icon System xsmall`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarIcon(
  systemName: "house",
  size: .xsmall
)
.setBadgeOn(isOn: true)
`}</code></pre>
    <p>{`C. Avatar Icon System small`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarIcon(
  systemName: "house",
  size: .small
)
.setBadgeOn(isOn: true)
`}</code></pre>
    <p>{`D. Avatar Icon System medium`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarIcon(
  systemName: "house",
  size: .medium
)
.setBadgeOn(isOn: true)
`}</code></pre>
    <p>{`D. Avatar Icon System large`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarIcon(
  systemName: "house",
  size: .large
)
.setBadgeOn(isOn: true)
`}</code></pre>
    <h4>{`LGNAvatarIcon`}</h4>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/avatar/ios-avatar-initials.png"
      }}></img>
    </div>
    <p>{`A. Avatar Initial xxsmall`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarInitials(
  label: "Telkom Indonesia",
  size: .xxsmall
)
.setBadgeOn(isOn: true)
`}</code></pre>
    <p>{`B. Avatar Initial xsmall`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarInitials(
  label: "Telkom Indonesia",
  size: .xsmall
)
.setBadgeOn(isOn: true)
`}</code></pre>
    <p>{`C. Avatar Initial small`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarInitials(
  label: "Telkom Indonesia",
  size: .small
)
.setBadgeOn(isOn: true)
`}</code></pre>
    <p>{`D. Avatar Initial medium`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarInitials(
  label: "Telkom Indonesia",
  size: .medium
)
.setBadgeOn(isOn: true)
`}</code></pre>
    <p>{`E. Avatar Initial large`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarInitials(
  label: "Telkom Indonesia",
  size: .large
)
.setBadgeOn(isOn: true)
`}</code></pre>
    <h3><strong parentName="h3">{` Show Progress `}</strong></h3>
    <div {...{
      "className": "wrapper-display-component-three",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-three",
        "style": {
          "padding": "0"
        }
      }}>{`
    `}<div parentName="div">{`
      `}<img parentName="div" {...{
            "src": "https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F107c2742-c366-4973-ab4f-8d6899edbb63%2FScreen_Shot_2022-11-17_at_1.49.47_PM.png?table=block&id=2a783ca7-7283-44e4-afdc-2ac6e7499281&spaceId=35a14a17-7ee4-461e-a539-293d39e34c63&width=940&userId=00fada7e-1357-4bc5-acbd-74e1e4633cb0&cache=v2"
          }}></img>{`
    `}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Show Progress `}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three",
        "style": {
          "padding": "0"
        }
      }}>{`
    `}<div parentName="div">{`
      `}<img parentName="div" {...{
            "src": "https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F2e2fd47b-d3df-4174-a178-7edbe2e6d3b1%2FScreen_Shot_2022-11-17_at_1.50.00_PM.png?table=block&id=b5f71443-c7ba-4653-9277-314ecf2cfc04&spaceId=35a14a17-7ee4-461e-a539-293d39e34c63&width=960&userId=00fada7e-1357-4bc5-acbd-74e1e4633cb0&cache=v2"
          }}></img>{`
    `}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Not Show Progress `}</div>{`
  `}</div>
    </div>
    <p>{`This label attribute to customize show progress async from url for avatar.`}</p>
    <InlineNotification kind="warning" mdxType="InlineNotification">This attribute only run in `LGNAvatarIcon` source Async</InlineNotification>
    <p>{`add sources `}<inlineCode parentName="p">{`imageNamed: "Link"`}</inlineCode>{` , `}<inlineCode parentName="p">{`systemName: "Link"`}</inlineCode>{` or `}<inlineCode parentName="p">{`urlString: "Link"`}</inlineCode>{` where `}<inlineCode parentName="p">{`Link`}</inlineCode>{` consist the value of the image. And add `}<inlineCode parentName="p">{`size: .sizeAvatar`}</inlineCode>{` where `}<inlineCode parentName="p">{`.sizeAvatar`}</inlineCode>{` consist `}<inlineCode parentName="p">{`.xxsmall`}</inlineCode>{`, `}<inlineCode parentName="p">{`.xsmall`}</inlineCode>{`, `}<inlineCode parentName="p">{`.small`}</inlineCode>{`, `}<inlineCode parentName="p">{`.medium`}</inlineCode>{` or `}<inlineCode parentName="p">{`.large`}</inlineCode>{` finish it with `}<inlineCode parentName="p">{`.setPlaceholder(isProgressViewOn: true)`}</inlineCode>{` or `}<inlineCode parentName="p">{`.setPlaceholder(isProgressViewOn: false)`}</inlineCode></p>
    <p>{`This code sample demonstrates how to modify the show progress of the async avatar :`}</p>
    <p>{`A. Medium Show Progress`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarIcon(
    urlString: "https:someurl.co/image.png",
    size: .medium
)
.setBadgeOn(isOn: true)
.setPlaceholder(
    isProgressViewOn: true
)
`}</code></pre>
    <p>{`B. Medium Not Show Progress`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarIcon(
    urlString: "https:someurl.co/image.png",
    size: .medium
)
.setBadgeOn(isOn: true)
.setPlaceholder(
    isProgressViewOn: false
)
`}</code></pre>
    <div className="divi" />
    <h2>{`Properties`}</h2>
    <h3><strong parentName="h3">{` LGNAvatarImage `}</strong></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Properties`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`imageNamed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The name of the local asset that you want to use.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required or no default value`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The size of the avatar, there are three size: `}<inlineCode parentName="td">{`.xxsmall`}</inlineCode>{`, `}<inlineCode parentName="td">{`.xsmall`}</inlineCode>{`, `}<inlineCode parentName="td">{`.small`}</inlineCode>{`, `}<inlineCode parentName="td">{`.medium`}</inlineCode>{`, `}<inlineCode parentName="td">{`.large`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required or no default value`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`backgroundColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The color of the avatar background.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Color.LGNTheme.tertiary100`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Properties`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`systemName`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The name of the system name from SF Symbols that you want to use. (`}<a parentName="td" {...{
              "href": "https://developer.apple.com/design/resources/#sf-symbols"
            }}>{`https://developer.apple.com/design/resources/#sf-symbols`}</a>{`)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required or no default value`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The size of the avatar, there are three size: `}<inlineCode parentName="td">{`.xxsmall`}</inlineCode>{`, `}<inlineCode parentName="td">{`.xsmall`}</inlineCode>{`, `}<inlineCode parentName="td">{`.small`}</inlineCode>{`, `}<inlineCode parentName="td">{`.medium`}</inlineCode>{`, `}<inlineCode parentName="td">{`.large`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required or no default value`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`backgroundColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The color of the avatar background.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Color.LGNTheme.tertiary100`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Properties`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`urlString`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The url asset that you want to use. We recommend you to use .jpg, .jpeg, and .png format.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required or no default value`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The size of the avatar, there are three size: `}<inlineCode parentName="td">{`.xxsmall`}</inlineCode>{`, `}<inlineCode parentName="td">{`.xsmall`}</inlineCode>{`, `}<inlineCode parentName="td">{`.small`}</inlineCode>{`, `}<inlineCode parentName="td">{`.medium`}</inlineCode>{`, `}<inlineCode parentName="td">{`.large`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required or no default value`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`backgroundColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The color of the avatar background.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Color.LGNTheme.tertiary100`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3><strong parentName="h3">{` LGNAvatarIcon `}</strong></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Properties`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`imageNamed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The name of the local asset that you want to use.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required or no default value`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The size of the avatar, there are three size: `}<inlineCode parentName="td">{`.xxsmall`}</inlineCode>{`, `}<inlineCode parentName="td">{`.xsmall`}</inlineCode>{`, `}<inlineCode parentName="td">{`.small`}</inlineCode>{`, `}<inlineCode parentName="td">{`.medium`}</inlineCode>{`, `}<inlineCode parentName="td">{`.large`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required or no default value`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`iconColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The color of the icon.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Color.white`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`backgroundColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The color of the avatar background.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Color.LGNTheme.tertiary500`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Properties`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`systemName`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The name of the system name from SF Symbols that you want to use. (`}<a parentName="td" {...{
              "href": "https://developer.apple.com/design/resources/#sf-symbols"
            }}>{`https://developer.apple.com/design/resources/#sf-symbols`}</a>{` )`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required or no default value`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The size of the avatar, there are three size: `}<inlineCode parentName="td">{`.xxsmall`}</inlineCode>{`, `}<inlineCode parentName="td">{`.xsmall`}</inlineCode>{`, `}<inlineCode parentName="td">{`.small`}</inlineCode>{`, `}<inlineCode parentName="td">{`.medium`}</inlineCode>{`, `}<inlineCode parentName="td">{`.large`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required or no default value`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`iconColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The color of the icon.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Color.white`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`backgroundColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The color of the avatar background.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Color.LGNTheme.tertiary500`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Properties`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`urlString`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The url asset that you want to use. We recommend you to use .jpg, .jpeg, and .png format.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required or no default value`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The size of the avatar, there are three size: `}<inlineCode parentName="td">{`.xxsmall`}</inlineCode>{`, `}<inlineCode parentName="td">{`.xsmall`}</inlineCode>{`, `}<inlineCode parentName="td">{`.small`}</inlineCode>{`, `}<inlineCode parentName="td">{`.medium`}</inlineCode>{`, `}<inlineCode parentName="td">{`.large`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required or no default value`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`iconColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The color of the icon.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Color.white`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`backgroundColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The color of the avatar background.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Color.LGNTheme.tertiary500`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3><strong parentName="h3">{` LGNAvatarInitials `}</strong></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Properties`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`label`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The long text that you want to take the initials of to appear on the avatar.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required or no default value`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`fontFamily`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The font family for the label.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`""`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`textColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The color of the text string.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Color.white`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`backgroundColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The color of the avatar background.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Color.LGNTheme.primary500`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2>{`Example Project`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAvatarIcon(urlString: "https://picsum.photos/100", size: .small, iconColor: .blue, backgroundColor: .red)
LGNAvatarIcon(imageNamed: "foo", size: .small, backgroundColor: .gray)
LGNAvatarIcon(systemName: "heart", size: .small, backgroundColor: .clear)
  .setPlaceholder(isProgressViewOn: true, backgroundColor: .clear)
  .setPlaceholder(systemName: "xmark", isProgressViewOn: true, backgroundColor: .clear)
  .setPlaceholder(imageNamed: "asset-image", isProgressViewOn: true, backgroundColor: .clear)
  .setPlaceholder(label: "loading..", isProgressViewOn: true, fontFamily: "", textColor: .white, backgroundColor: .clear)
  .aspectRatio(contentMode: .fill)
  .setBackgroundColor(color: .red)
  .setIconColor(color: .blue)
  .setBadgeColor(color: .red)
  .setBadgeOn(isOn: true)

LGNAvatarImage(urlString: "https://picsum.photos/100", size: .small, backgroundColor: .green)
LGNAvatarImage(imageNamed: "foo", size: .small, backgroundColor: .blue)
LGNAvatarImage(systemName: "heart", size: .small, backgroundColor: .gray)
  .setPlaceholder(isProgressViewOn: true, backgroundColor: .clear)
  .setPlaceholder(systemName: "xmark", isProgressViewOn: true, backgroundColor: .clear)
  .setPlaceholder(imageNamed: "asset-image", isProgressViewOn: true, backgroundColor: .clear)
  .setPlaceholder(label: "loading..", isProgressViewOn: true, fontFamily: "", textColor: .white, backgroundColor: .clear)
  .aspectRatio(contentMode: .fill)
  .setBackgroundColor(color: .red)
  .setBadgeColor(color: .red)
  .setBadgeOn(isOn: true)

LGNAvatarInitials(label: "Juanita Vyatri", size: .small, textColor: .white, backgroundColor: Color.LGNTheme.primary500)
  .setBackgroundColor(color: .red)
  .setTextColor(color: .blue)
  .setBadgeColor(color: .red)
  .setBadgeOn(isOn: true)
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      